/* MOBILE/Badge/14 Medium */
.medium {
  font-size: 0.875rem;
  line-height: 0.875rem;
}

/* MOBILE/Badge/12 Small */
.small {
  font-size: 0.75rem;
  line-height: 0.75rem;
}

/* MOBILE/Badge/10 XSmall */
.xsmall {
  font-size: 0.625rem;
  line-height: 0.625rem;
}
