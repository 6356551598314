@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_900 from colors;

.menuButtonRoot {
  align-items: center;

  color: NEUTRAL_GRAY_900;
  display: flex;
}

.menuButtonRoot span {
  color: NEUTRAL_GRAY_900;
  font-size: 1rem;
  font-weight: 500;
  margin-left: 10px;
}