@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, NEUTRAL_GRAY_300 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value NEW_TABLET_PORTRAIT from breakpoints;

@value FOOTER_HEIGHT_MOBILE: 75px;
@value FOOTER_HEIGHT_TABLET: 91px;

.container {
  box-sizing: border-box; /* necessary when rendering in bottom sheet */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  border-top: 1px solid NEUTRAL_GRAY_300;
  position: fixed;
  bottom: 0;
  z-index: 4; /* one higher than bottom sheet */
  background-color: WHITE;
}

.nextButton {
  margin-left: 0.5rem;
  flex: 1;
}

.backButton {
  padding: 7px 1.5rem; /* reduce vertical padding by 1px to make buttons look the same size */
  flex: 1;
}

@media screen and (min-width: NEW_TABLET_PORTRAIT) {
  .container {
    padding: 1.5rem 2rem;
  }

  .nextButton,
  .backButton {
    min-width: 160px;
    flex: none;
  }
}
