@value breakpoints: '~styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

/* MOBILE/Display/48 Large */
.large,
.mobile.large {
  font-size: 3rem;
  line-height: 3.5rem;
}

/* MOBILE/Display/30 Small */
.small,
.mobile.small {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

/* MOBILE/Display/30/36 Large Number */
.largeNumber,
.mobile.largeNumber {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

/* Desktop/Display/96 Large */
.desktop.large {
  font-size: 6rem;
  line-height: 6.5rem;
}

/* Desktop/Display/72 Small */
.desktop.small {
  font-size: 4.5rem;
  line-height: 5.5rem;
}

/* Desktop/Display/42/48 Large Number */
.desktop.largeNumber {
  font-size: 2.625rem;
  line-height: 3rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  /* Desktop/Display/96 Large */
  .large {
    font-size: 6rem;
    line-height: 6.5rem;
  }

  /* Desktop/Display/72 Small */
  .small {
    font-size: 4.5rem;
    line-height: 5.5rem;
  }

  /* Desktop/Display/42/48 Large Number */
  .largeNumber {
    font-size: 2.625rem;
    line-height: 3rem;
  }
}
