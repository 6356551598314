.drawerItemRoot {
  position: relative;
}

.drawerItemText {
  margin-right: 0.5rem;
}

.drawerItemIcon,
.rail.open .drawerItemIcon {
  margin-right: 0.75rem !important;
}

.rail .drawerItemIcon {
  margin-right: 0 !important;
}

.rail .drawerItemText {
  display: none;
}

.rail.open .drawerItemText {
  display: block;
}

.rail .notificationIndicator {
  background-color: #25837b;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  right: 16px;
  height: 4px;
  width: 4px;
}
