.root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loadingIcon {
  animation: 2.4s linear infinite both loading_root_animation;
  max-width: 100px;
}

.helperText {
  margin-top: 0.5rem;
}

@keyframes loading_root_animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.loadingIconCircle {
  animation: 2.4s ease-in-out infinite both loading_circle_animation;
  display: block;
  fill: transparent;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-dasharray: 283;
  stroke-dashoffset: 250;
  stroke-width: 2px;
  transform-origin: 50% 50%;
}

@keyframes loading_circle_animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 250;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}
