@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_600 from colors;

.labelText {
  display: inline-block;
  padding: 0px 2px 4px;
}

.helperText {
  padding: 8px 2px 0;
}

.viewModeContainer {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.editIcon {
  color: NEUTRAL_GRAY_600;
  cursor: pointer;
  flex-shrink: 0;
}
