@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_500, NEUTRAL_GRAY_800, SHADES_WHITE from colors;

.badgeRoot {
  border: 1px solid transparent;
  border-radius: 32px;
  display: inline-flex;
  align-items: center;
}

.iconLeft {
  align-items: center;
  display: flex;
  margin-right: 3px;
}

.iconRight {
  align-items: center;
  display: flex;
  margin-left: 3px;
}

.filled {
  background-color: NEUTRAL_GRAY_500;
}

.filled > * {
  color: SHADES_WHITE;
}

.outlined {
  border-color: NEUTRAL_GRAY_800;
}

.outlined > * {
  color: NEUTRAL_GRAY_800;
}

.size10 {
  padding: 0.25rem;
}

.size12 {
  padding: 0.25rem 0.5rem;
}

.size14 {
  padding: 0.25rem 0.5rem;
}

.size10.numberOnly {
  padding: 2px 4px;
}

.size12.numberOnly {
  padding: 6px 8px 5px 8px;
}
.size14.numberOnly {
  padding: 5px 8px;
}

.badgeText {
  display: block;
}
