@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_200 from colors;

.menuListRoot {
  border: 2px solid NEUTRAL_GRAY_200;
  max-width: 250px;
  left: 0;
}

.menuListContent {
  list-style: none;
  padding: 0;
}
