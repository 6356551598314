@value colors: 'styles/colors.module.css';
@value SHADES_WHITE, PRIMARY_DEFAULT, PRIMARY_50, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300, NEUTRAL_GRAY_500 from colors;

@value INPUT_HEIGHT: 49px;

.search.searchInputWrapper {
  margin: 0;
}

.search.searchInputWrapper .searchInput {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  height: INPUT_HEIGHT;
}

.search.searchInputWrapper .searchInput:focus,
.search.searchInputWrapper .searchInput.searchInputFocused {
  border-color: NEUTRAL_GRAY_500;
  background-color: SHADES_WHITE;
}

.searchInputIconWrapper {
  height: 47px;
  width: 56px;
  margin-right: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
}

.searchInput:focus .searchInputIconWrapper,
.searchInput.searchInputFocused .searchInputIconWrapper {
  background-color: PRIMARY_50;
}

.searchInput:focus .searchInputIcon path,
.searchInput.searchInputFocused .searchInputIcon path {
  stroke: PRIMARY_DEFAULT;
}

@media (hover: hover) {
  .search .searchInput:hover,
  .search .searchInput.searchInputHovered {
    border-color: NEUTRAL_GRAY_300;
    background-color: NEUTRAL_GRAY_200;
  }
}
