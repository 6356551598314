@value breakpoints: '~styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

/* MOBILE/Body/18 Large */
.large,
.mobile.large {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

/* MOBILE/Body/16 Medium */
.medium,
.mobile.medium {
  font-size: 1rem;
  line-height: 1.5rem;
}

/* MOBILE/Body/14 Small */
.small,
.mobile.small {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

/* MOBILE/Body/12 XSmall */
.xsmall,
.mobile.xsmall {
  font-size: 0.75rem;
  line-height: 1rem;
}

/* MOBILE/Body/10 Tiny */
.tiny,
.mobile.tiny {
  font-size: 0.625rem;
  line-height: 1rem;
}

/* MOBILE/Body/8 Miniscule */
.miniscule,
.mobile.miniscule {
  font-size: 0.5rem;
  line-height: 0.5rem;
}

/* DESKTOP/Body/21 Large */
.desktop.large {
  font-size: 1.3125rem;
  line-height: 2rem;
}

/* DESKTOP/Body/18 Medium */
.desktop.medium {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

/* DESKTOP/Body/16 Small */
.desktop.small {
  font-size: 1rem;
  line-height: 1.5rem;
}

/* DESKTOP/Body/14 XSmall */
.desktop.xsmall {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

/* DESKTOP/Body/12 Tiny */
.desktop.tiny {
  font-size: 0.75rem;
  line-height: 1rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  /* DESKTOP/Body/21 Large */
  .large {
    font-size: 1.3125rem;
    line-height: 2rem;
  }

  /* DESKTOP/Body/18 Medium */
  .medium {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  /* DESKTOP/Body/16 Small */
  .small {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  /* DESKTOP/Body/14 XSmall */
  .xsmall {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  /* DESKTOP/Body/12 Tiny */
  .tiny {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}
