@value elevations: '~styles/elevations.module.css';
@value ELEVATION_XS, ELEVATION_SM, ELEVATION_MD, ELEVATION_LG, ELEVATION_XL from elevations;
@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_200, SHADES_WHITE from colors;

.root {
  padding: 16px;
  background: SHADES_WHITE;
  border: 1px solid NEUTRAL_GRAY_200;
  border-radius: 8px;
  width: 100%;
}

.disablePadding {
  padding: 0;
}

.elevation-xs {
  box-shadow: ELEVATION_XS;
}

.elevation-sm {
  box-shadow: ELEVATION_SM;
}

.elevation-md {
  box-shadow: ELEVATION_MD;
}

.elevation-lg {
  box-shadow: ELEVATION_LG;
}

.elevation-xl {
  box-shadow: ELEVATION_XL;
}
