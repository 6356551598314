@value colors: '~styles/colors.module.css';
@value SHADES_BLACK, NEUTRAL_GRAY_500, NEUTRAL_GRAY_600, NEUTRAL_GRAY_900, SHADES_WHITE, PRIMARY_DEFAULT, SECONDARY_DEFAULT, ERROR_900, SUCCESS_800, WARNING_800 from colors;

/* Font weight styling */
.regular {
  font-weight: normal;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

/* Alignment styling */
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.inherit {
  text-align: inherit;
}

.justify {
  text-align: justify;
}

/* Color styling */
.primary {
  color: PRIMARY_DEFAULT;
}

.secondary {
  color: SECONDARY_DEFAULT;
}

.textPrimary {
  color: NEUTRAL_GRAY_900;
}

.textSecondary {
  color: NEUTRAL_GRAY_600;
  font-weight: 700;
}

.textTertiary {
  color: NEUTRAL_GRAY_500;
}

.white {
  color: SHADES_WHITE;
}

.error {
  color: ERROR_900;
}

.success {
  color: SUCCESS_800;
}

.warning {
  color: WARNING_800;
}

/* Display styling */
.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

/* Margin styling */
.gutterBottom {
  margin-bottom: 0.5rem;
}

/* Text transform styling */
.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
