@value colors: '~styles/colors.module.css';

@value DARK_MODE_HOVER from colors;

.drawerLogoRoot {
  padding: 0 1.75rem;
  width: 100%;
}

.drawerLogoContent {
  align-items: center;
  display: flex;
  /* flex-shrink: 0; */
  height: 72px;
  width: 100%;
}

.drawerLogoContent > * {
  flex-shrink: 0;
}

.drawerLogoContent > *:not(:last-child) {
  margin-right: 0.75rem !important;
}

.drawerLogoText {
  display: none;
}

.drawerLogoText.open {
  display: block;
  height: 20px;
}

.drawerCloseIconContainer {
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.drawerCloseIconContainer:hover,
.drawerCloseIconContainer:focus,
.drawerCloseIconContainer:active {
  background-color: DARK_MODE_HOVER;
}

.drawerCloseIcon {
  color: #fff;
}
