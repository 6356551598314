.rootCollapse {
  min-height: 0px;
  overflow: hidden;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-duration: 300ms;
}

.rootCollapse.collapsed {
  height: 0;
}

.collapseChildrenContainer {
  display: flex;
  flex-direction: column;
}
