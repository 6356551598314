@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_400, NEUTRAL_GRAY_600 from colors;

.labelRoot {
  color: NEUTRAL_GRAY_600;
  display: block;
  font-size: 14px;
  line-height: 20px;
}

.labelRoot.disabled {
  color: NEUTRAL_GRAY_400;
}
