@value colors: '~styles/colors.module.css';
@value SHADES_WHITE, NEUTRAL_GRAY_100, NEUTRAL_GRAY_300, NEUTRAL_GRAY_500, NEUTRAL_GRAY_600, NEUTRAL_GRAY_900, ERROR_50, ERROR_600 from colors;

.textareaRoot {
  background-color: SHADES_WHITE;
  border: 1px solid NEUTRAL_GRAY_300;
  border-radius: 4px;
  color: NEUTRAL_GRAY_900;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  padding: 1rem;
  width: min-content;
  height: auto;
}

.textareaRoot.fullWidth {
  width: 100%;
}

.textareaInner {
  align-self: stretch;
  background: none;
  border: none;
  color: NEUTRAL_GRAY_900;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5rem;
  flex-grow: 1;
  overflow: hidden;
  padding: 0;
  resize: none;
  height: auto;
  width: 100%;
}

.textareaRoot.error {
  background-color: ERROR_50;
  border-color: ERROR_600;
}

.textareaRoot.error.hover,
.textareaRoot.error:hover {
  background-color: SHADES_WHITE;
  border-color: ERROR_600;
}

.textareaRoot.error.focus,
.textareaRoot.error:focus {
  background-color: SHADES_WHITE;
  border-color: ERROR_600;
  box-shadow: 0px 0px 8px rgba(220, 38, 38, 0.9);
  outline: 0;
}

.textareaRoot.hover:not(.error),
.textareaRoot:hover:not(.error) {
  border-color: NEUTRAL_GRAY_500;
}

.textareaRoot.focus:not(.error),
.textareaRoot:focus:not(.error) {
  border-color: NEUTRAL_GRAY_500;
  box-shadow: 0px 0px 8px rgba(94, 155, 242, 0.9);
  outline: 0;
}

.textareaRoot.disabled:not(.error),
.textareaRoot:disabled:not(.error) {
  background-color: NEUTRAL_GRAY_100;
  border-color: NEUTRAL_GRAY_300;
  box-shadow: none;
  color: NEUTRAL_GRAY_500; /* TODO verify this is the correct color */
  pointer-events: none;
}

.textareaInner.focus,
.textareaInner:focus {
  outline: 0;
}

.textareaInner.disabled,
.textareaInner:disabled {
  color: NEUTRAL_GRAY_500;
  caret-color: transparent;
}

.iconClear {
  color: NEUTRAL_GRAY_300;
  cursor: pointer;
  display: inline-flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.iconClearHidden {
  color: transparent;
  display: inline-flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.iconLeft {
  margin-right: 0.5rem;
}

.iconRight {
  display: flex;
  margin-left: 0.5rem;
}
