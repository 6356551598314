@value colors: '~styles/colors.module.css';

@value NEUTRAL_GRAY_800, NEUTRAL_GRAY_900 from colors;

.drawerRoot {
  align-items: center;
  background-color: NEUTRAL_GRAY_800;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  white-space: nowrap;
  width: 0;
  z-index: 1000;
}

.drawerRoot.permanent {
  position: relative;
  width: 252px;
}

.left {
  left: 0;
  top: 0;
}

.right {
  right: 0;
  top: 0;
}

.drawerRoot.rail {
  position: fixed;
  top: 0;
  width: 72px;
}

.drawerRoot.rail.open {
  width: 252px;
}

.drawerRoot.temporary {
  position: fixed;
  width: 0;
}

.drawerRoot.temporary.open {
  width: 252px;
}

.overlay {
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  height: 100vh;
  opacity: 0;
  position: absolute;
  transition: opacity 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: 100%;
  z-index: -1;
}

.overlay.open {
  display: block;
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 999;
}
