@value colors: '~styles/colors.module.css';
@value elevations: '~styles/elevations.module.css';

@value GRADIENT_PRIMARY_DEFAULT, GRADIENT_PRIMARY_HOVER, GRADIENT_PRIMARY_DISABLED, PRIMARY_DISABLED, PRIMARY_DEFAULT, PRIMARY_FOCUSED, PRIMARY_HOVER, PRIMARY_FOCUSED, NEUTRAL_GRAY_100, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300, NEUTRAL_GRAY_400, NEUTRAL_GRAY_500, NEUTRAL_GRAY_600, NEUTRAL_GRAY_800, NEUTRAL_GRAY_900, SHADES_WHITE from colors;
@value ELEVATION_FOCUSED from elevations;

.root {
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  justify-content: center;
  font-weight: 500;
  letter-spacing: 0.02em;
  padding: 0.25rem;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
}

.auto {
  width: auto;
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.primary {
  background: GRADIENT_PRIMARY_DEFAULT;
  background-clip: padding-box;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0);
  color: #ffffff;
}

.primary:hover {
  background: GRADIENT_PRIMARY_HOVER;
  background-repeat: no-repeat;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0);
}

.primary:focus {
  background: GRADIENT_PRIMARY_HOVER;
  background-repeat: no-repeat;
  background-clip: padding-box;
  border: 1px solid PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.primary:disabled {
  background: GRADIENT_PRIMARY_DISABLED;
  border: 1px solid rgba(0, 0, 0, 0);
}

.secondary {
  background: none;
  border: 1px solid PRIMARY_DEFAULT;
  border-radius: 4px;
  color: PRIMARY_DEFAULT;
}

.secondary:hover {
  background: PRIMARY_DEFAULT;
  color: SHADES_WHITE;
}

.secondary:focus {
  border-color: PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.secondary:disabled {
  border-color: GRADIENT_PRIMARY_DISABLED;
  color: GRADIENT_PRIMARY_DISABLED;
}

.text {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  color: PRIMARY_DEFAULT;
}

.text:hover {
  color: PRIMARY_HOVER;
}

.text:focus {
  color: PRIMARY;
  border-color: PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.text:disabled {
  color: GRADIENT_PRIMARY_DISABLED;
}

.textLight {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  color: NEUTRAL_GRAY_200;
}

.textLight:hover {
  color: NEUTRAL_GRAY_100;
}

.textLight:focus {
  color: NEUTRAL_GRAY_300;
  border-color: PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.textLight:disabled {
  color: GRADIENT_PRIMARY_DISABLED;
}

.textDark {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  color: NEUTRAL_GRAY_900;
}

.textDark:hover {
  color: NEUTRAL_GRAY_800;
}

.textDark:focus {
  color: NEUTRAL_GRAY_900;
  border-color: PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.textDark:disabled {
  color: GRADIENT_PRIMARY_DISABLED;
}

.textSecondary {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  color: NEUTRAL_GRAY_600;
}

.textSecondary:hover {
  color: NEUTRAL_GRAY_500;
}

.textSecondary:focus {
  color: NEUTRAL_GRAY_600;
  border-color: PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.textSecondary:disabled {
  color: GRADIENT_PRIMARY_DISABLED;
}

.small {
  padding: 0.25rem;
}

.medium {
  padding: 0.25rem;
}

.large {
  padding: 0.25rem;
}

.disabled {
  cursor: default;
}
