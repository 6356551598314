@value colors: '~styles/colors.module.css';

@value GRADIENT_PRIMARY_DEFAULT, GRADIENT_PRIMARY_HOVER, GRADIENT_PRIMARY_DISABLED, PRIMARY_DISABLED, PRIMARY_DEFAULT, PRIMARY_FOCUSED, PRIMARY_HOVER, PRIMARY_FOCUSED, NEUTRAL_GRAY_100, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300, NEUTRAL_GRAY_400, NEUTRAL_GRAY_500, NEUTRAL_GRAY_600,SHADES_WHITE, SHADES_BLACK from colors;

.buttonTextRoot {
  font-weight: 600;
  text-transform: capitalize;
}

.primary {
  color: SHADES_WHITE;
}

.secondary {
  color: PRIMARY_DEFAULT;
}

.secondary:hover {
  color: SHADES_WHITE;
}

.secondary:disabled {
  color: GRADIENT_PRIMARY_DISABLED;
}

.text {
  color: PRIMARY_DEFAULT;
}

.text:hover {
  color: PRIMARY_HOVER;
}

.text:focus {
  color: PRIMARY;
}

.text:disabled {
  color: GRADIENT_PRIMARY_DISABLED;
}

.textLight {
  color: NEUTRAL_GRAY_200;
}

.textLight:hover {
  color: NEUTRAL_GRAY_100;
}

.textLight:focus {
  color: NEUTRAL_GRAY_300;
}

.textLight:disabled {
  color: GRADIENT_PRIMARY_DISABLED;
}

.textDark {
  color: NEUTRAL_GRAY_500;
}

.textDark:hover {
  color: NEUTRAL_GRAY_400;
}

.textDark:focus {
  color: NEUTRAL_GRAY_600;
}

.textDark:disabled {
  color: GRADIENT_PRIMARY_DISABLED;
}

.dark {
  color: SHADES_WHITE;
}

.darkHollow {
  color: SHADES_BLACK;
}

/* MOBILE/Button/18 Large */
.large {
  font-size: 1.125rem;
  letter-spacing: 0.02em;
  line-height: 1.75rem;
}

/* MOBILE/Button/16 Medium */
.medium {
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.5rem;
}

/* MOBILE/Button/14 Small */
.small {
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  line-height: 1.25rem;
}

/* MOBILE/Button/12 XSmall */
.xsmall {
  font-size: 0.75rem;
  letter-spacing: 0.02em;
  line-height: 1rem;
}
