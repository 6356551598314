@value breakpoints: '~styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.radioGroupLegend {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  /* margin-left: 0.5rem; */
}

@media screen and (min-width: TABLET_WIDTH) {
  .checkboxGroupLegend {
    font-size: 18px;
  }
}
