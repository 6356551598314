@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_100, NEUTRAL_GRAY_200, NEUTRAL_GRAY_500, NEUTRAL_GRAY_900 from colors;

.radioOptionRoot {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  padding: 4px 12px;
  /* user-select: none; */
}

.radioOptionRoot:not(:first-child) {
  margin-top: 1rem;
}

.radioOptionRoot.hover,
.radioOptionRoot:hover {
  background-color: NEUTRAL_GRAY_100;
}

.radioOptionRoot.focus,
.radioOptionRoot:focus {
  background-color: NEUTRAL_GRAY_200;
  outline: 0;
}

.radioOptionRoot.disabled,
.radioOptionRoot:disabled {
  color: NEUTRAL_GRAY_500;
  pointer-events: none;
}

.radioOptionLabelWrapper {
  margin: 0 1rem;
}

.radioOptionCircleRoot {
  align-items: center;
  color: NEUTRAL_GRAY_900;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  height: 20px;
  position: relative;
  width: 20px;
}

.radioOptionCircleInput {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  margin: 0;
  width: 100%;
}

.radioOptionCircleIcon {
  display: flex;
  position: relative;
}

.radioOptionCircleIcon img {
  height: 100%;
  width: 100%;
}
