@value breakpoints: '~styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.checkboxGroupLegend {
  font-size: 16px;
  font-weight: 600;
}

.helperText {
  margin-top: 0.5rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .checkboxGroupLegend {
    font-size: 18px;
  }
}
