.textInputRoot {
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.labelText {
  display: inline-block;
  padding: 0px 2px 4px;
}

.helperText {
  padding: 8px 2px 0;
}
