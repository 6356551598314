@value colors: '~styles/colors.module.css';
@value SHADES_WHITE, NEUTRAL_GRAY_100, NEUTRAL_GRAY_300, NEUTRAL_GRAY_500, NEUTRAL_GRAY_600, NEUTRAL_GRAY_900, ERROR_50, ERROR_600 from colors;

.inputRoot {
  align-items: center;
  background-color: SHADES_WHITE;
  border: 1px solid NEUTRAL_GRAY_300;
  border-radius: 4px;
  color: NEUTRAL_GRAY_900;
  display: flex;
  font-size: 1rem;
  height: 3.5rem;
  justify-content: space-between;
  margin-right: 1rem;
  padding: 0 1rem;
  width: min-content;
}

.inputRoot.fullWidth {
  width: 100%;
}

.inputInner {
  background: none;
  border: none;
  color: NEUTRAL_GRAY_900;
  font-family: inherit;
  font-size: 16px;
  flex-grow: 1;
}

.inputRoot.error {
  background-color: ERROR_50;
  border-color: ERROR_600;
}

.inputRoot.error.hover,
.inputRoot.error:hover {
  background-color: SHADES_WHITE;
  border-color: ERROR_600;
}

.inputRoot.error.focus,
.inputRoot.error:focus {
  background-color: SHADES_WHITE;
  border-color: ERROR_600;
  box-shadow: 0px 0px 8px rgba(220, 38, 38, 0.9);
  outline: 0;
}

.inputRoot.hover:not(.error),
.inputRoot:hover:not(.error) {
  border-color: NEUTRAL_GRAY_500;
}

.inputRoot.focus:not(.error),
.inputRoot:focus:not(.error) {
  border-color: NEUTRAL_GRAY_500;
  box-shadow: 0px 0px 8px rgba(94, 155, 242, 0.9);
  outline: 0;
}

.inputRoot.disabled:not(.error),
.inputRoot:disabled:not(.error) {
  background-color: NEUTRAL_GRAY_100;
  border-color: NEUTRAL_GRAY_300;
  box-shadow: none;
  color: NEUTRAL_GRAY_500; /* TODO verify this is the correct color */
  pointer-events: none;
}

.inputInner.focus,
.inputInner:focus {
  outline: 0;
}

.inputInner.disabled,
.inputInner:disabled {
  color: NEUTRAL_GRAY_500;
  caret-color: transparent;
}

.iconClear {
  color: NEUTRAL_GRAY_300;
  cursor: pointer;
  display: inline-flex;
}

.iconClearHidden {
  color: transparent;
  display: inline-flex;
}

.iconLeft {
  display: inline-flex;
  margin-right: 0.5rem;
}

.iconRight {
  display: inline-flex;
  margin-left: 1rem;
}
