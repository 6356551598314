@value colors: '~styles/colors.module.css';
@value elevations: '~styles/elevations.module.css';
@value GRADIENT_PRIMARY_DEFAULT, PRIMARY_700, NEUTRAL_GRAY_50, NEUTRAL_GRAY_400, SECONDARY_800, SHADES_WHITE from colors;
@value ELEVATION_SM from elevations;

.avatarRoot {
  align-items: center;
  background: none;
  box-shadow: ELEVATION_SM;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: 0;
  position: relative;
}

.avatarRoot.notificationVisible {
  background: GRADIENT_PRIMARY_DEFAULT;
  padding: 1.5px;
}

.avatarContent {
  align-items: center;
  background-color: NEUTRAL_GRAY_400;
  border-radius: 50%;
  border: 1.5px solid SHADES_WHITE;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.img {
  border-radius: 50%;
  color: transparent;
  height: 100%;
  object-fit: cover;
  text-align: center;
  width: 100%;
}

.avatarRoot.size24 {
  height: 24px;
  width: 24px;
}

.avatarRoot.size24 .avatarContent {
  border-width: 1.5px;
}

.avatarRoot.size32 {
  height: 32px;
  width: 32px;
}

.avatarRoot.size32 .avatarContent {
  border-width: 2px;
}

.avatarRoot.size48 {
  height: 48px;
  width: 48px;
}

.avatarRoot.size48 .avatarContent {
  border-width: 2px;
}

.avatarRoot.size64 {
  height: 64px;
  width: 64px;
}

.avatarRoot.size64 .avatarContent {
  border-width: 2px;
}

.avatarRoot.size80 {
  height: 80px;
  width: 80px;
}

.avatarRoot.size80 .avatarContent {
  border-width: 3px;
}

.avatarRoot.size112 {
  height: 112px;
  width: 112px;
}

.avatarRoot.size112 .avatarContent {
  border-width: 4px;
}

.avatarText {
  color: NEUTRAL_GRAY_50;
}

.avatarInteractive {
  cursor: pointer;
}

.textSize24 {
  font-size: 0.75rem;
}

.textSize32 {
  font-size: 0.875rem;
}

.textSize48 {
  font-size: 1.125rem;
}

.notificationRoot {
  align-items: center;
  background: SECONDARY_800;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  position: absolute;
}

.notificationRoot.size24 {
  height: 12px;
  top: -1px;
  right: -5px;
  width: 12px;
}

.notificationRoot.size32 {
  height: 16px;
  top: -2px;
  right: -7px;
  width: 16px;
}

.notificationRoot.size48 {
  height: 16px;
  top: -1px;
  right: -3px;
  width: 16px;
}
