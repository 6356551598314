@value colors: '~styles/colors.module.css';
@value elevations: '~styles/elevations.module.css';

@value SOLID_BRICK_RED, DIAGONAL_GRADIENT, GRADIENT_PRIMARY_DEFAULT, GRADIENT_PRIMARY_HOVER, GRADIENT_PRIMARY_DISABLED, PRIMARY_DISABLED, PRIMARY_DEFAULT, PRIMARY_FOCUSED, PRIMARY_HOVER, PRIMARY_FOCUSED, NEUTRAL_GRAY_100, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300, NEUTRAL_GRAY_400, NEUTRAL_GRAY_500, NEUTRAL_GRAY_600, NEUTRAL_GRAY_700, NEUTRAL_GRAY_800, NEUTRAL_GRAY_900, SHADES_WHITE from colors;
@value ELEVATION_FOCUSED from elevations;

.root {
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  justify-content: center;
  font-weight: 600;
  letter-spacing: 0.02em;
  padding: 0.475rem 1.5rem;
  text-decoration: none;
  width: 100%;
}

.root:disabled {
  pointer-events: none;
}

.fullWidth {
  width: 100%;
}

.interactive:hover .iconRight {
  left: 0.25rem;
}

.auto {
  width: auto;
}

.linkAuto {
  width: fit-content;
}

.iconLeft {
  display: inline-flex;
  margin-right: 0.5rem;
  transition: margin-left 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.iconRight {
  display: inline-flex;
  position: relative;
  margin-left: 0.5rem;
  left: 0;
  transition: left 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.primary {
  background: DIAGONAL_GRADIENT;
  background-clip: padding-box;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0);
  color: SHADES_WHITE;
}

.primary > * {
  color: SHADES_WHITE;
}

.primary:hover {
  background: GRADIENT_PRIMARY_HOVER;
  background-repeat: no-repeat;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.primary:focus {
  background: GRADIENT_PRIMARY_HOVER;
  background-repeat: no-repeat;
  background-clip: padding-box;
  border: 1px solid PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.primary:disabled {
  background: GRADIENT_PRIMARY_DISABLED;
  border: 1px solid rgba(0, 0, 0, 0);
}

.secondary {
  background: none;
  border: 1px solid SOLID_BRICK_RED;
  border-radius: 4px;
  color: SOLID_BRICK_RED;
}

.secondary > * {
  color: SOLID_BRICK_RED;
}

.secondary:hover {
  background: SOLID_BRICK_RED;
  color: SHADES_WHITE;
  cursor: pointer;
}

.secondary:hover > * {
  color: SHADES_WHITE;
}

.secondary:focus {
  border-color: PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.secondary:disabled {
  border-color: GRADIENT_PRIMARY_DISABLED;
  color: GRADIENT_PRIMARY_DISABLED;
}

.secondary:disabled > * {
  color: GRADIENT_PRIMARY_DISABLED;
}

.text {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  color: SOLID_BRICK_RED;
}

.text > * {
  color: SOLID_BRICK_RED;
}

.text:hover {
  color: PRIMARY_HOVER;
  cursor: pointer;
}

.text:hover > * {
  color: PRIMARY_HOVER;
}

.text:focus {
  color: PRIMARY;
  border-color: PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.text:focus > * {
  color: PRIMARY;
}

.text:disabled,
.text:disabled > * {
  color: GRADIENT_PRIMARY_DISABLED;
}

.textLight {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  color: NEUTRAL_GRAY_200;
}

.textLight > * {
  color: NEUTRAL_GRAY_200;
}

.textLight:hover {
  color: NEUTRAL_GRAY_100;
  cursor: pointer;
}

.textLight:hover > * {
  color: NEUTRAL_GRAY_100;
}

.textLight:focus {
  color: NEUTRAL_GRAY_300;
  border-color: PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.textLight:focus > * {
  color: NEUTRAL_GRAY_300;
}

.textLight:disabled,
.textLight:disabled > * {
  color: GRADIENT_PRIMARY_DISABLED;
}

.textDark {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  color: NEUTRAL_GRAY_900;
}

.textDark > * {
  color: NEUTRAL_GRAY_900;
}

.textDark:hover {
  color: NEUTRAL_GRAY_800;
  cursor: pointer;
}

.textDark:hover > * {
  color: NEUTRAL_GRAY_800;
}

.textDark:focus {
  color: NEUTRAL_GRAY_900;
  border-color: PRIMARY_FOCUSED;
  box-shadow: ELEVATION_FOCUSED;
}

.textDark:focus > * {
  color: NEUTRAL_GRAY_900;
}

.textDark:disabled,
.textDark:disabled > * {
  color: GRADIENT_PRIMARY_DISABLED;
}

.dark {
  background: NEUTRAL_GRAY_900;
  border: 1px solid rgba(0, 0, 0, 0);
  color: SHADES_WHITE;
}

.dark:hover {
  background: NEUTRAL_GRAY_700;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.dark:focus {
  background: NEUTRAL_GRAY_700;
  box-shadow: ELEVATION_FOCUSED;
}

.dark:disabled {
  background: NEUTRAL_GRAY_700;
  border: 1px solid rgba(0, 0, 0, 0);
}

.darkHollow {
  background: transparent;
  border: 1px solid NEUTRAL_GRAY_900;
  color: NEUTRAL_GRAY_900;
}

.darkHollow:hover {
  border-color: NEUTRAL_GRAY_700;
  color: NEUTRAL_GRAY_900;
  cursor: pointer;
}

.darkHollow:focus {
  border-color: NEUTRAL_GRAY_700;
  color: NEUTRAL_GRAY_900;
  box-shadow: ELEVATION_FOCUSED;
}

.darkHollow:disabled {
  border: 1px solid NEUTRAL_GRAY_700;
  color: NEUTRAL_GRAY_700;
}

.xxsmall {
  font-size: 0.625rem;
  line-height: 1rem;
  padding: 0.125rem 0.25rem;
}

.xsmall {
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.25rem 0.5rem;
}

.xsmall .iconLeft {
  display: inline-flex;
  margin-right: 0.25rem;
}

.xsmall .iconRight {
  margin-left: 0.25rem;
}

.small {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.475rem 1.5rem;
}

.medium {
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 1.5rem;
}

.large {
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 0.625rem 1.5rem;
}

.capitalize {
  text-transform: capitalize;
}
