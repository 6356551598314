@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_300, NEUTRAL_GRAY_700 from colors;

.dividerRoot {
  border-color: NEUTRAL_GRAY_300;
  border-style: solid;
  margin: 0;
}

.dividerRoot.darkMode {
  border-color: NEUTRAL_GRAY_700;
}

.horizontal {
  border-width: 0 0 1px;
  width: 100%;
}

.vertical {
  border-width: 0 1px 0 0;
  height: 100%;
  width: 0;
}
