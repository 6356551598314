@value breakpoints: '~styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

/* MOBILE/Title/27 XLarge */
.xlarge,
.mobile.xlarge {
  font-size: 1.6785rem;
  line-height: 2rem;
}

/* MOBILE/Title/24 Large */
.large,
.mobile.large {
  font-size: 1.5rem;
  line-height: 2rem;
}

/* MOBILE/Title/18 Medium */
.medium,
.mobile.medium {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

/* MOBILE/Title/16 Small */
.small,
.mobile.small {
  font-size: 1rem;
  line-height: 1.25rem;
}

/* MOBILE/Title/14 XSmall */
.xsmall,
.mobile.xsmall {
  font-size: 0.875rem;
  line-height: 1rem;
}

/* MOBILE/Title/16 XXXSmall */
.xxxsmall,
.mobile.xxxsmall {
  font-size: 1rem;
  line-height: 1.25rem;
}

/* DESKTOP/Title/48 Large */
.desktop.large {
  font-size: 3rem;
  line-height: 3.75rem;
}

/* DESKTOP/Title/36 Medium */
.desktop.medium {
  font-size: 2.25rem;
  line-height: 3rem;
}

/* DESKTOP/Title/27 Small */
.desktop.small {
  font-size: 1.6785rem;
  line-height: 2.5rem;
}

/* DESKTOP/Title/24 XSmall */
.desktop.xsmall {
  font-size: 1.5rem;
  line-height: 2rem;
}

/* DESKTOP/Title/21 XXSmall */
.desktop.xxsmall {
  font-size: 1.3125rem;
  line-height: 1.75rem;
}

/* DESKTOP/Title/18 XXXSmall */
.desktop.xxxsmall {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.uppercase {
  letter-spacing: 0.04rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  /* DESKTOP/Title/48 Large */
  .large {
    font-size: 3rem;
    line-height: 3.75rem;
  }

  /* DESKTOP/Title/36 Medium */
  .medium {
    font-size: 2.25rem;
    line-height: 3rem;
  }

  /* DESKTOP/Title/27 Small */
  .small {
    font-size: 1.6785rem;
    line-height: 2.5rem;
  }

  /* DESKTOP/Title/24 XSmall */
  .xsmall {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  /* DESKTOP/Title/21 XXSmall */
  .xxsmall {
    font-size: 1.3125rem;
    line-height: 1.75rem;
  }

  /* DESKTOP/Title/18 XXXSmall */
  .xxxsmall {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}
