@value colors: '~styles/colors.module.css';
@value SHADES_BLACK, SHADES_WHITE, NEUTRAL_GRAY_100, NEUTRAL_GRAY_200, NEUTRAL_GRAY_500, ERROR_50, ERROR_600 from colors;

.checkboxBaseRoot {
  align-items: center;
  color: SHADES_BLACK;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  height: 18px;
  position: relative;
  width: 18px;
}

.checkboxBaseRoot.hover,
.checkboxBaseRoot:hover {
  background-color: NEUTRAL_GRAY_100;
}

.checkboxBaseRoot.focus,
.checkboxBaseRoot:focus {
  background-color: NEUTRAL_GRAY_200;
}

.checkboxBaseRoot.disabled,
.checkboxBaseRoot:disabled {
  color: NEUTRAL_GRAY_500;
  pointer-events: none;
}

.checkboxInner {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
}

.checkboxIcon {
  cursor: pointer;
}

.checkboxRoot {
  /* align-items: center; */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  padding: 0.25rem 0.75rem;
  margin-top: 1rem;
  /* user-select: none; */
  width: auto;
}

.checkboxRoot.hover,
.checkboxRoot:hover {
  background-color: NEUTRAL_GRAY_100;
}

.checkboxRoot.focus,
.checkboxRoot:focus {
  background-color: NEUTRAL_GRAY_200;
}

.checkboxRoot.disabled {
  color: NEUTRAL_GRAY_500;
  pointer-events: none;
}

.checkboxRoot.error {
  background-color: ERROR_50;
  border-color: ERROR_600;
}

.checkboxRootIcon {
  margin-top: 2px;
}

.baseCheckboxLabel {
  font-weight: 400;
  line-height: 150%;
  margin-left: 0.5rem;
}

.checkboxLabel {
  composes: baseCheckboxLabel;
  font-size: 16px;
}

.helperText {
  padding: 8px 2px 0;
}
