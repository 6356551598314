@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_600, ERROR_600 from colors;

.helperTextRoot {
  color: NEUTRAL_GRAY_600;
  font-size: 12px;
  line-height: 16px;
}

.helperTextRoot.error {
  color: ERROR_600;
}

.textWrapper {
  display: flex;
}

.icon {
  flex-shrink: 0;
  margin-right: 4px;
}
