@value breakpoints: '~styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

/* MOBILE/Overline/16 Large */
.large .mobile.large {
  font-size: 1rem;
  letter-spacing: 0.08em;
  line-height: 1.5rem;
}

/* MOBILE/Overline/14 Medium */
.medium,
.mobile.medium {
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  line-height: 1.25rem;
}

/* MOBILE/Overline/12 Small */
.small,
.mobile.small {
  font-size: 0.75rem;
  letter-spacing: 0.08em;
  line-height: 1.25rem;
}

/* MOBILE/Overline/10 XSmall */
.xsmall,
.mobile.xsmall {
  font-size: 0.625rem;
  letter-spacing: 0.08em;
  line-height: 1rem;
}

/* DESKTOP/Overline/21 Medium */
.desktop.medium {
  font-size: 1.3125rem;
  letter-spacing: 0.08em;
  line-height: 2rem;
}

/* DESKTOP/Overline/16 Small */
.desktop.small {
  font-size: 1rem;
  letter-spacing: 0.08em;
  line-height: 1.5rem;
}

/* DESKTOP/Overline/14 Xsmall */
.desktop.xsmall {
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  line-height: 1.25rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  /* DESKTOP/Overline/21 Medium */
  .medium {
    font-size: 1.3125rem;
    letter-spacing: 0.08em;
    line-height: 2rem;
  }

  /* DESKTOP/Overline/16 Small */
  .small {
    font-size: 1rem;
    letter-spacing: 0.08em;
    line-height: 1.5rem;
  }

  /* DESKTOP/Overline/14 Xsmall */
  .xsmall {
    font-size: 0.875rem;
    letter-spacing: 0.08em;
    line-height: 1.25rem;
  }
}
