@value colors: '~styles/colors.module.css';
@value NEUTRAL_GRAY_200, NEUTRAL_GRAY_300, NEUTRAL_GRAY_900, DARK_MODE_HOVER, DARK_MODE_SELECTED, SHADES_WHITE from colors;

.menuItemRoot {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0.75rem 1.5rem;
  width: 100%;
}

.menuItemRoot.dense {
  padding: 0.5rem 1rem;
}

.menuItemRoot.selected {
  background-color: NEUTRAL_GRAY_300;
}

.menuItemRoot:hover,
.menuItemRoot:active,
.menuItemRoot:focus {
  background-color: NEUTRAL_GRAY_200;
  cursor: pointer;
}

.menuItemLink:hover .menuItemRoot,
.menuItemLink:focus .menuItemRoot,
.menuItemLink:active .menuItemRoot {
  background-color: NEUTRAL_GRAY_200;
}

.iconLeft {
  align-items: center;
  color: NEUTRAL_GRAY_900;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75rem;
}

.iconRight {
  align-items: center;
  color: NEUTRAL_GRAY_900;
  display: flex;
  margin-left: 0.75rem;
}

.menuItemLink {
  text-decoration: none;
  color: inherit;
  width: 100%;
}

.menuItemText {
  margin-top: 2px;
  width: 100%;
}

/* Dark Mode Styles */
.menuItemRoot.darkMode.selected {
  background-color: DARK_MODE_SELECTED;
}

.menuItemRoot.darkMode:hover,
.menuItemRoot.darkMode:focus,
.menuItemRoot.darkMode:active {
  background-color: DARK_MODE_HOVER;
  cursor: pointer;
}

.menuItemLink:hover .menuItemRoot.darkMode,
.menuItemLink:focus .menuItemRoot.darkMode,
.menuItemLink:active .menuItemRoot.darkMode {
  background-color: DARK_MODE_HOVER;
}

.menuItemRoot.darkMode.selected .menuItemText {
  color: NEUTRAL_GRAY_900;
}

.menuItemRoot.darkMode.selected:hover .menuItemText,
.menuItemRoot.darkMode.selected:hover .iconLeft,
.menuItemRoot.darkMode.selected:hover .iconRight,
.menuItemRoot.darkMode.selected:active .menuItemText,
.menuItemRoot.darkMode.selected:active .iconLeft,
.menuItemRoot.darkMode.selected:active .iconRight,
.menuItemRoot.darkMode.selected:focus .menuItemText,
.menuItemRoot.darkMode.selected:focus .iconLeft,
.menuItemRoot.darkMode.selected:focus .iconRight,
.menuItemLink:hover .menuItemRoot.darkMode,
.menuItemLink:focus .menuItemRoot.darkMode,
.menuItemLink:hover .menuItemRoot.darkMode.selected .menuItemText,
.menuItemLink:hover .menuItemRoot.darkMode.selected .iconLeft,
.menuItemLink:hover .menuItemRoot.darkMode.selected .iconRight,
.menuItemLink:active .menuItemRoot.darkMode.selected .menuItemText,
.menuItemLink:active .menuItemRoot.darkMode.selected .iconLeft,
.menuItemLink:active .menuItemRoot.darkMode.selected .iconRight,
.menuItemLink:focus .menuItemRoot.darkMode.selected .menuItemText,
.menuItemLink:focus .menuItemRoot.darkMode.selected .iconLeft,
.menuItemLink:focus .menuItemRoot.darkMode.selected .iconRight {
  color: SHADES_WHITE;
}

.darkMode .iconLeft,
.darkMode .iconRight {
  color: SHADES_WHITE;
}

.darkMode.selected .iconLeft,
.darkMode.selected .iconRight {
  color: NEUTRAL_GRAY_900;
}
