.drawerSectionTitleRoot {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  height: 44px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.08em;
  padding: 0 1.5rem;
  width: 100%;
}
